import { useStaticQuery, graphql } from "gatsby"

const ALL_EPHARMACY_PRODUCTS = graphql`
  query MedicineSearchProducts {
    allProducts {
      nodes {
        brand
        molecule
        productTitle
        rxRequired
        rxLevel
        category
        classification
        itemCode
        price_list {
          price_list
          vatex_unit_price
          vat
        }
      }
    }
  }
`

export const useEpharmacyProducts = () => {
  const data = useStaticQuery(ALL_EPHARMACY_PRODUCTS)

  const excludedTitles = [
    "Abiraterone acetate 250mg Tablet (Zytiga 120s)",
    "Ustekinumab 45mg/0.5mL Pre-filled syringe (Stelara)",
    "Ibrutinib 140mg Capsule (Imbruvica 90s)",
    "Selexipag 1600mcg Film-Coated Tablet (Uptravi 60s)",
    "Golimumab 50mg/0.5mL Auto-injection (Simponi)",
  ]
  const excludedBrands = [
    "Uptravi + Opsumit",
    "Invega Trinza",
    "Imbruvica 90s",
    "Remicade",
  ]

  const filteredProducts = data?.allProducts?.nodes.filter(
    (product) =>
      !excludedTitles.includes(product.productTitle) &&
      !excludedBrands.includes(product.brand)
  )

  data.allProducts.nodes = filteredProducts

  return data
}
